import { getCollections } from "@/api/requests"
import firebase from 'firebase/app'
import { saveCollections, loadCollections } from '@/common/language'

export default {
  name: "Programs",

  components: {
    BaseImg: () => import("@/components/base/Img"),
    BaseSection: () => import("@/components/base/Section"),
    BaseSectionHeading: () => import("@/components/base/SectionHeading"),
    BaseProgramPreview: () => import("@/components/base/ProgramPreview"),
    BaseScrollButton: () => import("@/components/base/ScrollButton"),
    BaseErrorMsg: () => import('@/components/base/ErrorMsg'),
  },
  data() {
    return {
      collections: [],
      heroimage: require("@/assets/img/herosmall.jpg"),
      errorMessage: {
        title: 'Erreur',
        text: 'Lorem ipsum dolor sit amet consecutor...',
        btns: [
          { txt: 'D\'accord', link: '#check' },
          { txt: 'Pas d\'accord', link: '#disagree' },
        ]
      }
    };
  },
  mounted: function () {
    this.loadCollections()
  },
  methods: {
    async loadCollections() {
      this.collections = await loadCollections()
      let allCollections = await getCollections()
      this.collections = await this.patchCollections(allCollections)
      await saveCollections(this.collections)
    },
    async patchCollections(collections) {
      // Workaround to show a Free collection
      let updatedCollections = [].concat(collections)
      const currentUser = firebase.auth().currentUser;
      let addFreeCollections = false
      if (currentUser === null) {
        addFreeCollections = true
      } else {
        const unlockAllRef = firebase.database().ref('/users/' + currentUser.uid + '/unlockAll');
        const unlockAll = await unlockAllRef.once('value').then((snapshot) => {
          return snapshot.val()
        })
        addFreeCollections = unlockAll !== true
      }
      if (addFreeCollections) {
        let ab0 = null
        let ab1 = null
        for (const collection of collections) {
          for (const program of collection.programs) {
            if (program.id === 19) {
              ab0 = program
            } else if (program.id === 20) {
              ab1 = program
            }
            if (ab0 !== null && ab1 !== null) {
              break
            }
          }
        }
        if (ab0 !== null && ab1 !== null) {
          let freeCollection = {
            id: 0,
            title: this.$i18n.t("free"),
            programs: [ab0, ab1]
          }
          updatedCollections = [freeCollection].concat(collections)
        }
      }
      return updatedCollections
    },
  }
};
