<template>
  <v-container class="px-4" id="singleprogram">
    <div v-if="!program" class="text-center">
      <v-progress-circular indeterminate color="primary" size="70" />
    </div>
    <div v-else>
      <v-card-title style="padding-left: 12px">
        {{ program.title }}
      </v-card-title>
      <base-section v-if="program.trailer_url" style="padding: 12px">
        <video-player :options="videoOptions"/>
      </base-section>
      <base-section style="padding-top: 12px">
        <v-container class="fill-height d-block text-center">
          <base-body class="d-block">
            {{ styles }} &bullet; {{ teachers }}
          </base-body>
          <base-body class="d-block">
            {{ program.desc }}
          </base-body>

          <v-row class="mt-3">
            <v-col
              v-for="(cours, index) in program.classes"
              :key="index"
              cols="12"
              md="4"
              sm="6"
            >
            <router-link :to="`/course/${cours.id}`">
              <base-course-preview v-bind="cours" align="left" horizontal/>
            </router-link>
            </v-col>
          </v-row>
        </v-container>
      </base-section>
    </div>
  </v-container>
</template>
<script>
import VideoPlayer from "@/components/base/VideoPlayer"
import { getProgram } from "@/api/requests"

export default {
  name: "SingleProgram",

  components: {
    BaseSection: () => import("@/components/base/Section"),
    BaseBody: () => import("@/components/base/Body"),
    BaseCoursePreview: () => import("@/components/base/CoursePreview"),
    VideoPlayer
  },
  data() {
    return {
      id: this.$route.params.id,
      program: null
    }
  },
  computed: {
    videoOptions() {
      return {
        autoplay: false,
        controls: true,
        preload: "auto",
        poster: this.program.trailer_pic_url,
        sources: [
          {
            src: this.program.trailer_url,
            type: "application/x-mpegURL"
          }
        ]
      }
    },
    styles() {
      return this.program.name_styles.replaceAll(",", ", ")
    },
    teachers() {
      return this.program.name_teachers.replaceAll(",", ", ")
    }
  },
  mounted() {
    this.loadProgram()
  },
  methods: {
    async loadProgram() {
      this.program = await getProgram(this.id)
    }
  }
};
</script>
