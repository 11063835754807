<template>
  <div id="paymentGiftCard">
      <section-hero v-bind="giftCard"></section-hero>
      <section-abo></section-abo>
      <base-scroll-button></base-scroll-button>
      <base-contact style="padding:0!important"></base-contact>
      <base-social-media></base-social-media>
  </div>
</template>

<script>
export default {
  name: "PaymentGiftCard",
  data() {
    return {
      giftCard: [
        {
          title: this.$i18n.t("app_name_short"),
          subtitle: this.$i18n.t("gift_cards"),
          image: require("@/assets/GiftCardRed.png")
        }
      ],
    }
  },
  components: {
    SectionHero: () => import('@/components/sections/giftCard/Hero'),
    SectionAbo: () => import('@/components/sections/giftCard/Abo'),
    BaseScrollButton: () => import('@/components/base/ScrollButton'),
    BaseContact: () => import('@/components/base/Contact'),
    BaseSocialMedia: () => import('@/components/base/SocialMedia'),
  }
}
</script>
