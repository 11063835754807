<template>
  <v-container class="px-4">
    <p class="text-center mt-12">{{ $t("feature4_text") }}</p>
    <iframe src="https://app.acuityscheduling.com/schedule.php?owner=21650886" title="Schedule Appointment" width="100%" height="900" frameBorder="0"></iframe>
  </v-container>
</template>

<script>
export default {
  name: "LiveScheduler",
};
</script>
