<template>
  <v-container fluid>
    <v-row justify="center">
      <v-subheader></v-subheader>
      <v-expansion-panels focusable popout>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template>
              <v-row no-gutters align="center" class="spacer">
                <v-col cols="4" sm="2" md="1">
                  <v-icon :color="userParams.color" v-text="userParams.icon"> </v-icon>
                </v-col>
                <v-col  sm="5" md="3">
                  <strong v-html="userParams.email"></strong>
                  <span v-if="userParams.total" class="grey--text">
                    &nbsp;({{ userParams.total }})
                  </span>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="mt-2 text-center">{{ userParams.content }}</p>
            <span class="d-block text-center mt-2">
              <v-btn elevation="2" text color="primary" @click="logout">
                <v-icon icon>mdi-login-variant</v-icon>
                {{ $t("logout") }}
              </v-btn>
            </span>
            <span class="d-block text-center mt-2">
              <v-btn elevation="2" text color="destructive" @click="deleteAccountAskConfirmation">
                <v-icon icon>mdi-delete-forever-outline</v-icon>
                {{ $t("delete_account") }}
              </v-btn>
              <v-dialog
                v-model="dialog"
                width="500"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    {{ $t("delete_account") }}
                  </v-card-title>
                  <v-card-text>
                    {{ $t("delete_account_confirmation") }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="onYesDeleteAccount">{{ $t("yes") }}</v-btn>
                    <v-btn color="primary" @click="onNoDeleteAccount">{{ $t("no") }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-progress-circular
                indeterminate
                color="primary"
                v-if="deletingAccount"
              ></v-progress-circular>
            </span>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row no-gutters>
              <v-col cols="4" sm="2" md="1">
                <v-icon :color="subscription.color" v-text="subscription.icon"> </v-icon>
              </v-col>
              <v-col  sm="5" md="3">
                <span>{{ subscription.name }}</span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="mt-2 text-center">{{ subscription.content }}</p>
            <span class="d-block text-center mt-2">
              <a v-if="subscription.stripePortalURL" :href='subscription.stripePortalURL' target="_blank">
                {{ $t("subscriptions") }}
              </a>
              <a v-else :href='contactUsURL()' target="_blank">
                {{ $t("contact_us") }}
              </a>
            </span>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row no-gutters align="center" class="spacer">
              <v-col cols="4" sm="2" md="1">
                <v-icon :color="languages.color" v-text="languages.icon"> </v-icon>
              </v-col>
              <v-col  sm="5" md="3">
                <span>{{ languages.title }}</span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-container fluid>
              <v-radio-group v-model="radioGroup">
                <v-radio v-for="language in languages.codes" :key="language" :label="$t(language)" :value="language">
                </v-radio>
               </v-radio-group>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <a class="Settings-help" :href='contactUsURL()' target="_blank">
        <v-card-text dense elevation="2" class="ml-5">
          <v-row no-gutters>
            <v-col cols="4" sm="2" md="1">
              <v-icon icon>mdi-email-outline</v-icon>
            </v-col>
            <v-col  sm="5" md="3">
              <span>{{ $t("contact_us") }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </a>
      <a class="Settings-help" :href='helpURL()' target="_blank">
        <v-card-text dense elevation="2" class="ml-5">
          <v-row no-gutters>
            <v-col cols="4" sm="2" md="1">
              <v-icon icon>mdi-help-circle-outline</v-icon>
            </v-col>
            <v-col  sm="5" md="3">
              <span>{{ $t("help") }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </a>
      <router-link class="Settings-help" :to='termsPath()'>
        <v-card-text dense elevation="2" class="ml-5">
          <v-row no-gutters>
            <v-col cols="4" sm="2" md="1">
              <v-icon icon>mdi-clipboard-text-outline</v-icon>
            </v-col>
            <v-col  sm="5" md="3">
              <span>{{ $t("terms_and_conditions") }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </router-link>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase/app'
import { overridenLanguage, saveOverridenLanguage, defaultLanguage, supportedLanguages, initLanguage, helpURL, contactUsURL } from "@/common/language"
import { postSubscriptionStatus, createStripeCustomerPortalSession, deleteAccount } from "@/api/requests"
import { language, termsPath } from "@/common/language"

export default {
  name: "Settings",

  components: {},

  data() {
    const allLanguagesCodes = supportedLanguages.concat([defaultLanguage])
    return {
      userParams: {
        color: "grey",
        icon: "mdi-account",
        name: "",
        email: firebase.auth().currentUser.email,
        content: this.$i18n.t("signed_in_as") + firebase.auth().currentUser.email,
      },
      subscription: {
        color: "grey",
        icon: "mdi-backup-restore",
        name: this.$i18n.t("subscription"),
        content: this.$i18n.t("getting_subscription_status"),
        status: {},
        stripePortalURL: null
      },
      languages: {
        color: "grey",
        icon: "mdi-alert-circle-outline",
        title: this.$i18n.t("change_language"),
        codes: allLanguagesCodes,
      },
      radioGroup: defaultLanguage,
      dialog: false,
      deletingAccount: false,
    }
  },
  watch: {
    async radioGroup(val) {
      const locale = await overridenLanguage()
      if (val !== locale) {
        await saveOverridenLanguage(val)
        await initLanguage(null)
        this.$router.go()
      }
    }
  },
  async mounted() {
    const locale = await overridenLanguage()
    this.radioGroup = locale

    await this.getSubscriptionStatus()
  },
  methods: {
    async logout() {
      try {
        await firebase.auth().signOut()
        this.$router.push("/login")
      } catch (error) {
        console.log("Logout error: ", error)
      }
    },
    async deleteAccountAskConfirmation() {
      this.dialog = true
    },
    async onYesDeleteAccount() {
      this.dialog = false
      this.deletingAccount = true
      try {
        await deleteAccount()
        await firebase.auth().signOut()
        this.$router.push("/login")
      } catch (error) {
        console.log("Delete account error: ", error)
      }
      this.deletingAccount = false
    },
    async onNoDeleteAccount() {
      this.dialog = false
    },
    helpURL() {
      return helpURL()
    },
    contactUsURL() {
      return contactUsURL()
    },
    termsPath() {
      return termsPath()
    },
    async getSubscriptionStatus() {
      const uid = firebase.auth().currentUser.uid
      const status = await postSubscriptionStatus(uid)
      this.subscription.status = status
      this.subscription.content = this.$i18n.t("getting_subscription_status")

      if (status.hasUnlockAllNote === true) {
        const unlockAllNote = status.unlockAllNote
        if (unlockAllNote.startsWith("/codes/: ")) {
          const code = unlockAllNote.replace("/codes/: ", "").substring(0, 8);
          this.subscription.content = this.$i18n.t("code_activated", { code: code })
          return
        } else if (unlockAllNote.startsWith("/giftCards/: ")) {
          const code = unlockAllNote.replace("/giftCards/: ", "").substring(0, 8);
          this.subscription.content = this.$i18n.t("gift_card_activated", { code: code })
          return
        }
      }

      if (status.hasNoActiveSubscription === true) {
        this.subscription.content = this.$i18n.t("no_subscription")
        return
      }

      if (status.hasIOSSubscription === true) {
        this.subscription.content = this.$i18n.t("app_store_subscription")
        return
      }

      if (status.hasAndroidSubscription === true) {
        this.subscription.content = this.$i18n.t("play_store_subscription")
        return
      }

      if (status.hasStripeSubscription) {
        const subscriptionInfosForStripe = status.subscriptionInfosForStripe
        const customerId = subscriptionInfosForStripe.customer
        const stripePortal = await createStripeCustomerPortalSession(customerId)
        this.subscription.stripePortalURL = stripePortal.portal_url

        const currentPeriodEnd = subscriptionInfosForStripe.current_period_end
        const planInterval = subscriptionInfosForStripe.plan_interval
        const planIntervalCount = subscriptionInfosForStripe.plan_interval_count
        const cancelAtEnd = subscriptionInfosForStripe.cancel_at_period_end
        let planDurationString = planIntervalCount + " " + planInterval
        if ("year" === planInterval) {
          if (planIntervalCount == 1) {
            planDurationString = this.$i18n.t("year", { n: planIntervalCount })
          } else {
            planDurationString = this.$i18n.t("years", { n: planIntervalCount })
          }
        } else if ("month" === planInterval) {
          if (planIntervalCount == 1) {
            planDurationString = this.$i18n.t("month", { n: planIntervalCount })
          } else {
            planDurationString = this.$i18n.t("months", { n: planIntervalCount })
          }
        }
        const currentPeriodEndDate = new Date(currentPeriodEnd * 1000)
        const currentPeriodEndString = currentPeriodEndDate.toLocaleDateString(language(), {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
        if (cancelAtEnd) {
          this.subscription.content = this.$i18n.t("stripe_subscription_renewal_off", {
            planDuration: planDurationString,
            subscriptionEnd: currentPeriodEndString
          })
        } else {
          const planAmount = subscriptionInfosForStripe.plan_amount
          const planTaxPercentage = subscriptionInfosForStripe.tax_percent
          const planPriceTTC = (planAmount * (planTaxPercentage / 100.0 + 1)) / 100
          const planPriceFormatted = planPriceTTC.toFixed(2) + "€"
          this.subscription.content = this.$i18n.t("stripe_subscription_renewal_on", {
            planDuration: planDurationString,
            subscriptionEnd: currentPeriodEndString,
            price: planPriceFormatted
          })
        }
        return
      }

      this.subscription.content = this.$i18n.t("could_not_get_subscription_status")
    }
  },
};
</script>
<style scoped>
  .Settings-content {
    text-align: center;
  }
  .Settings-help {
    width: 100%;
    text-decoration: none;
  }
</style>
