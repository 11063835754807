import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css';

// Translation provided by Vuetify (javascript)
import {fr} from 'vuetify/es5/locale/fr'
import {en} from 'vuetify/es5/locale/en'
import {de} from 'vuetify/es5/locale/de'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#146698',
            secondary: '#3ACEB2',
            christmas: '#C41E3A',
            accent: '#f8f8f8',
            error: '#E3ADAC',
            white: '#FFFFFF',
            black: '#000000',
            destructive: '#D32F2F',
          },
        },
      },
      lang: {
        locales: { fr, en, de },
        current: 'fr',
      },
    treeShake: true
});
