<template>
    <div id="payment">
        <section-hero :is6Months="is6Months" :isTimeLimited="isTimeLimited" :isWelcomeBack="isWelcomeBack" :is29="is29" :is20="is20" :isTest="isTest" v-bind="payment"></section-hero>
        <section-abo :is6Months="is6Months" :isTimeLimited="isTimeLimited" :isWelcomeBack="isWelcomeBack" :is29="is29" :is20="is20" :isTest="isTest"></section-abo>
        <base-scroll-button></base-scroll-button>
        <base-contact></base-contact>
        <base-social-media></base-social-media>
    </div>
</template>

<script>
export default {
    name: 'PaymentCheckout',
    props: {
      is6Months: {
        type: Boolean,
        default: true
      },
      isTimeLimited: {
        type: Boolean,
        default: false
      },
      isWelcomeBack: {
        type: Boolean,
        default: false
      },
      is29: {
        type: Boolean,
        default: false
      },
      is20: {
        type: Boolean,
        default: false
      },
      isTest: {
        type: Boolean,
        default: false
      }
    },
    components: {
        SectionHero: () => import('@/components/sections/payment/Hero'),
        SectionAbo: () => import('@/components/sections/payment/AboCheckout'),
        BaseScrollButton: () => import('@/components/base/ScrollButton'),
        BaseContact: () => import('@/components/base/Contact'),
        BaseSocialMedia: () => import('@/components/base/SocialMedia'),

    },
    data() {
       return {
            payment: [
                {
                    title: this.$i18n.t("app_name_short"),
                    subtitle: this.$i18n.t("yoga_moment"),
                    text: this.is6Months ? this.$i18n.t("6_months_yoga") : this.$i18n.t("one_year_yoga"),
                    btnTxt: this.is20 ? this.$i18n.t("welcome_back_price"): (this.is29 ? this.$i18n.t("29_price"): (this.is6Months ? this.$i18n.t("promo_price") : this.$i18n.t("promo_price_1_year"))),
                    barr: this.$i18n.t("instead_of"),
                    price: this.is6Months ? this.$i18n.t("old_price") : this.$i18n.t("old_price_1_year"),
                    btnLink: '#subscription'                }
            ],
       }
    },
}
</script>

<style lang="scss" scoped>



</style>
