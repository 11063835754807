<template>
<div id="useGiftCard">
  <section-hero v-bind="code"></section-hero>
  <section-steps></section-steps>
  <base-scroll-button></base-scroll-button>
</div>
</template>

<script>
export default {
  name: 'UseGiftCard',
  components: {
    SectionHero: () =>
      import('@/components/sections/giftCard/Hero'),
    SectionSteps: () =>
      import('@/components/sections/useGiftCard/Steps'),
    BaseScrollButton: () =>
      import('@/components/base/ScrollButton'),
  },
  data() {
    return {
      code: [{
        title: this.$i18n.t("app_name_short"),
        subtitle: this.$i18n.t("use_your_gift_card"),
        image: require("@/assets/GiftCardRed.png")
      }]
    }
  }
}
</script>
