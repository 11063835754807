<template>
  <section id="favorite" style="padding: 0">
    <v-container class="px-4 py-12">
      <base-section-heading title="Favoris" />
       <!-- <v-row class="mt-3">
          <v-col
            v-for="(cours, index) in favorites" :key="index"
            cols="12"
            md="4"
            sm="6"
          >

            <base-course-preview v-bind="cours" align="left" horizontal/>

          </v-col>
        </v-row> -->
    </v-container>
  </section>
</template>

<script>
export default {
  name: "Favorite",
  components: {
    BaseSectionHeading: () => import("@/components/base/SectionHeading"),
    // BaseCoursePreview: () => import("@/components/base/CoursePreview"),
  },
  data(){
      return {

      }
  },
    computed: {
        cours: function() {
            return this.$store.getters.getCourseById(this.id);
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
