<template>
  <section id="login">
    <v-img :src="image" :style="cssProps" fixed>
      <div class="Login-bck">
        <v-row align="center" justify="center">
          <v-card
            class="Login-card elevation-12 pa-6 ma-6 rounded-xl"
            max-width="600px"
            min-width="360px"
            width="100%"
          >
            <v-img
              :src="require('@/assets/logo_sans_fond.png')"
              class="mr-10 mx-2 ml-10"
              contain
              height="50"
            />

            <v-row>
              <v-col cols="12">
                <v-container v-if="resetPasswordSuccess" class="text-center">
                  <v-alert>{{ $t("reset_password_success") }}</v-alert>
                  <a href="/login">{{ $t("login") }}</a>
                </v-container>
                <v-card v-else class="Forgot-card px-4 pt-4">
                  <v-card-text>
                    <!-- remove .prevent to reload the page at submit -->
                    <v-form
                      ref="ResetForm"
                      v-model="valid"
                      lazy-validation
                    >
                      <!-- remove .prevent to reload the page at submit -->
                      <v-row>
                        <v-col cols="12">
                          <p>{{ reset }}</p>
                          <v-text-field
                            v-model="loginEmail"
                            :label='$t("email")'
                            required
                            color="teal accent-3"
                            filled
                            rounded
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" class="text-center">
                          <v-alert v-if="loginEmailError" type="error">{{ loginEmailError }}</v-alert>
                          <base-btn
                            :tile="false"
                            color="primary"
                            width="100%"
                            rounded
                            @click="validate"
                          >
                            {{ reset }}
                          </base-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </div>
    </v-img>
  </section>
</template>

<script>
import firebase from 'firebase/app'

export default {
  name: "Login",

  components: {
    BaseBtn: () => import("@/components/base/Btn"),
  },

  data() {
    return {
      image: 'require("../../../assets/img/meditation.jpeg")',

      cssProps: {
        height: `auto`,
        maxWidth: `calc(100% + 2rem)`,
        minHeight: `calc(100vh - 7rem)`,
        backgroundImage: `url(${require("../../../assets/img/meditation.jpeg")})`,
        backgroundSize: `cover`,
        objectFit: `cover`,
        objectPosition: `center center`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `100% 100%`,
      },

      reset: this.$i18n.t("reset_password"),
      valid: true,
      loginEmail: "",
      loginEmailError: null,
      resetPasswordSuccess: false
    };
  },
  computed: {},
  methods: {
    validate() {
      this.loginEmailError = null
      try {
        firebase.auth().sendPasswordResetEmail(this.loginEmail)
        this.resetPasswordSuccess = true
      } catch (error) {
        console.log("Email/password connect error: ", error)
        this.loginEmailError = error.message
      }
    },
  },
};
</script>
<style scoped>
.Login-bck {
  background-color: rgba(241, 237, 237, 0.411);
  height: 100%;
}
.Login-card {
  margin-top: auto;
  margin-bottom: auto;
}
.Forgot-card {
  box-shadow: none !important;
}
</style>
