import firebase from 'firebase/app'

export default {
    name: "Login",

    components: {
      BaseBtn: () => import("@/components/base/Btn"),
    },

    data() {
      return {
        image: 'require("../../../assets/img/login.jpg")',

        cssProps: {
          height: `auto`,
          maxWidth: `calc(100% + 2rem)`,
          minHeight: `calc(100vh - 7rem)`,
          backgroundImage: `url(${require("../../../assets/img/login.jpg")})`,
          backgroundSize: `cover`,
          objectFit: `cover`,
          objectPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `100% 100%`,
        },
        tab: 0,
        tabs: [{ name: this.$i18n.t("login") }, { name: this.$i18n.t("register") }],
        valid: true,

        registerEmail: "",
        registerPassword: "",
        registerAcceptedTos: false,
        registerEmailError: null,
        registerAppleError: null,
        registerFacebookError: null,
        showRegisterPassword: false,
        loginPassword: "",
        loginEmail: "",
        loginEmailError: null,
        loginAppleError: null,
        loginFacebookError: null,
        showLoginPassword: false,
        forgotMsg: this.$i18n.t("forgot_password")
      };
    },
    methods: {
      validateEmailRegister: async function() {
        this.registerEmailError = null
        if (this.registerAcceptedTos === false) {
          this.registerEmailError = this.$i18n.t("accept_tos_error")
          return
        }
        try {
          await firebase.auth().createUserWithEmailAndPassword(this.registerEmail, this.registerPassword)
          this.redirectAfterLogin()
        } catch (error) {
          console.log("Email/password register error: ", error)
          this.registerEmailError = error.message
          if (error.code === "auth/email-already-in-use") {
            this.registerEmailError = this.$i18n.t("email_already_in_use")
          } else if (error.code === "auth/invalid-email") {
            this.registerEmailError = this.$i18n.t("invalid_email")
          } else if (error.code === "auth/invalid-password") {
            this.registerEmailError = this.$i18n.t("invalid_password")
          } else if (error.code === "auth/weak-password") {
            this.registerEmailError = this.$i18n.t("invalid_password")
          }
        }
      },
      validateAppleRegister: async function() {
        console.log("Apple register in progress")
        const provider = new firebase.auth.OAuthProvider('apple.com')
        try {
          await firebase.auth().signInWithPopup(provider)
          this.redirectAfterLogin()
        } catch (error) {
          console.log("Apple register error: ", error)
          this.registerAppleError = error.message
        }
      },
      validateFacebookRegister: async function() {
        console.log("Facebook register in progress")
        const provider = new firebase.auth.FacebookAuthProvider()
        try {
          await firebase.auth().signInWithPopup(provider)
          this.redirectAfterLogin()
        } catch (error) {
          console.log("Facebook register error: ", error)
          this.registerFacebookError = error.message
        }
      },
      redirectAfterLogin() {
        this.$router.push(this.$route.query.redirect || '/')
      },
      validateEmailLogin: async function() {
        this.loginEmailError = null
        try {
          await firebase.auth().signInWithEmailAndPassword(this.loginEmail, this.loginPassword)
          this.redirectAfterLogin()
        } catch (error) {
          console.log("Email/password connect error: ", error)
          this.loginEmailError = error.message
          if (error.code === "auth/wrong-password") {
            this.loginEmailError = this.$i18n.t("wrong_password")
          } else if (error.code === "auth/user-not-found") {
            this.loginEmailError = this.$i18n.t("user_not_found")
          }
        }
      },
      validateAppleLogin: async function() {
        console.log("Apple login in progress")
        const provider = new firebase.auth.OAuthProvider('apple.com')
        try {
          await firebase.auth().signInWithPopup(provider)
          this.redirectAfterLogin()
        } catch (error) {
          console.log("Apple connect error: ", error)
          this.loginAppleError = error.message
        }
      },
      validateFacebookLogin: async function() {
        console.log("Facebook login in progress")
        const provider = new firebase.auth.FacebookAuthProvider()
        try {
          await firebase.auth().signInWithPopup(provider)
          this.redirectAfterLogin()
        } catch (error) {
          console.log("Facebook connect error: ", error)
          this.loginFacebookError = error.message
        }
      },
      reset() {
        this.loginEmailError = null
        this.$refs.form.reset();
      },
      resetValidation() {
        this.loginEmailError = null
        this.$refs.form.resetValidation();
      },
    },
};
