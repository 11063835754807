<template>
<div>
  <v-card class="mx-auto mt-5" max-width="800" elevation="22">
    <v-container>
      <p class="text-h3">{{ $t("thanks_gift_card_available") }}</p>
      <p>{{ $t("you_will_gift_card_by_email") }}</p>
    </v-container>
  </v-card>
  <base-scroll-button></base-scroll-button>
  <base-contact></base-contact>
  <base-social-media></base-social-media>
</div>
</template>

<script>
export default {
  name: 'GiftCardSuccess',
  components: {
    BaseScrollButton: () =>
      import('@/components/base/ScrollButton'),
    BaseContact: () =>
      import('@/components/base/Contact'),
    BaseSocialMedia: () =>
      import('@/components/base/SocialMedia')
  }
}
</script>
